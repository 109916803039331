import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject, Subscription, of } from 'rxjs'
import { catchError,  takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { PagesModels } from 'src/app/pages'
import { LoggingService } from 'src/app/shared'
import { MetadataSelectors } from 'src/app/metadata'
import { MemberSelectors } from 'src/app/member'
import { CalcUtilService } from 'src/app/calculator/services/calc-utils'
import { CalculatorActions } from 'src/app/calculator'
import { FundType } from 'src/app/client/types'
import {  FASAutoCalculatorRequest, FASAutoCalculatorResponse } from  '../../../../calculator/models/estimated-pension.data'
import { CalculatorService } from 'src/app/calculator/services/calculator-service'

@Component({
  selector: 'psrs-retirement-calculator-template',
  templateUrl: './retirement-calculator.component.html',
  styleUrls: ['./retirement-calculator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetirementCalculatorComponent implements OnInit {
  private _page: PagesModels.PageDefinition
  isDisabled: boolean = true  
  unsubscribe$ = new Subject<void>()
  fundType: FundType
  @Input() set page (value: PagesModels.PageDefinition) {
    this._page = value
  }

  get page (): PagesModels.PageDefinition {
    return this._page
  }

  TEMPLATE_KEY: any = {
    Birthday: 'birthday',
    Gender: 'gender',
    Tier: 'tier',
    StartDate: 'startDate',
    EndDate: 'endDate',
    Salary: 'salary',
    LeaveTime: 'leaveTime',
    YearsOfService: 'yearsofService',
    InitialServiceProvided: 'initialServiceProvided',
    FirstDateEligibleToRetire: 'firstDateEligibleToRetire',
    CensusDate: 'censusDate',
    IncludeBeneficiary: 'includeBeneficiary',
    DisclaimerModal: 'modal',
    ResultsFooter: 'resultsfooter'
  }

  subs: Subscription = new Subscription()
  submissionValues: any = {}
  submissionStatus: any = {}
  submissionId: string
  formInputs: any[]
  modalHtml: string
  footerHtml: string
  displayKeys: any = {}
  status: any
  isValid: boolean = true
  @ViewChild('disclaimerModal')
  disclaimerModal: TemplateRef<any>
  disclaimerModalId: string
  disableYOS = false
  originalYOS: any
  errorMessage: string
  overwriteYOS: boolean = false
  @ViewChild('birthdayComponentRef') birthdayComponentRef
  @ViewChild('startDateComponentRef') startDateComponentRef
  @ViewChild('endDateComponentRef') endDateComponentRef
  calProcessing: boolean = false

  constructor (
    private elRef: ElementRef,
    private loggingService: LoggingService,
    private modalService: NgbModal,
    private location: Location,
    private store: Store,
    private calcUtilService: CalcUtilService,
    private calculatorService: CalculatorService,
    private router: Router
  ) {}

  ngOnInit (): void {
    this.store.select(MemberSelectors.getMember).subscribe(md => {
      let userStatus = this.status = md.status.toLocaleLowerCase()
      this.status = userStatus === 'active'      
      this.fundType = parseInt(localStorage.getItem('fundType'))
      if (this.fundType === FundType.ChicagoLabor) {        
        this.status = (userStatus === 'active' || userStatus === 'active - disabled')       
      } else if (
        this.fundType === FundType.MobileAL ||
        this.fundType === FundType.WHG ||
        this.fundType === FundType.WHP ||
        this.fundType === FundType.WHF ||
        this.fundType === FundType.Largo
      ) {
        this.disableYOS = true
      }

      this.isDisabled = !this.status
      this.redrawComponent()
    })
  }

  ngOnDestroy (): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subs.unsubscribe()
  }

  get beneficiaryIncludesGender (): Boolean {
    return (
      this.page.content.findIndex(
        x =>
          x.parentKey === this.TEMPLATE_KEY.IncludeBeneficiary &&
          x.key === 'includeGender' &&
          x.value == 'true'
      ) > 0
    )
  }

  get hasValidationErrors (): Boolean {
    let hasError: Boolean = false
    for (let i = 0; i < this.formInputs.length; i++) {
      if (!this.submissionStatus[this.formInputs[i].key]) {
        hasError = true
        break
      }
    }

    return hasError || !this.isValid
  }

  get hasBothDateValues (): Boolean {
    return (
      this.submissionValues[this.TEMPLATE_KEY.StartDate] &&
      this.submissionValues[this.TEMPLATE_KEY.EndDate]
    )
  }

  redrawComponent (): void {
    this.buildDisplayStructure()

    this.submissionId = `Pension_Values_${this.page.id}`
    this.submissionValues = JSON.parse(
      sessionStorage.getItem(this.submissionId)
    )
    let isRevised = localStorage.getItem('revised')
    if (!this.submissionValues || !isRevised) {
      this.submissionValues = {}
      let contentValues = this.page.content.filter(
        cnt =>
          cnt.key.endsWith('Value') && cnt.type === 'MetadataMultiReference'
      )
      for (let i = 0; i < contentValues.length; i++) {
        var metadataKey = contentValues[i].value[0]
        var contentKey = contentValues[i].key.replace('Value', '')
        this.store
          .select(MemberSelectors.resolveMetadata, metadataKey)
          .subscribe(mdProp => {
            if (mdProp) {
              this.submissionValues[contentKey] = mdProp
              this.submissionStatus[contentKey] = true
            } else {
              this.submissionValues[contentKey] = null
              this.submissionStatus[contentKey] = false
            }
          })
      }

      if (this.hasBothDateValues) {
        if (this.fundType === FundType.MobileAL) {
          this.submissionValues[this.TEMPLATE_KEY.YearsOfService] =
            this.calcUtilService.calculateTimeElapsed(
              new Date(this.submissionValues[this.TEMPLATE_KEY.StartDate]),
              new Date(this.submissionValues[this.TEMPLATE_KEY.EndDate])
            )
          this.submissionStatus[this.TEMPLATE_KEY.YearsOfService] =
            this.submissionValues[this.TEMPLATE_KEY.YearsOfService] != null
        }
      }

      if (!this.submissionValues[this.TEMPLATE_KEY.LeaveTime]) {
        this.submissionValues[this.TEMPLATE_KEY.LeaveTime] = {
          years: 0,
          months: 0,
          days: 0
        }
      }

      if (this.submissionValues[this.TEMPLATE_KEY.IncludeBeneficiary]) {
        let elapsedTimeAge = this.calcUtilService.calculateTimeElapsed(
          new Date(this.submissionValues[this.TEMPLATE_KEY.IncludeBeneficiary]),
          new Date()
        )
        this.submissionValues[this.TEMPLATE_KEY.IncludeBeneficiary] = {
          age: elapsedTimeAge?.years,
          gender: ''
        }
        this.submissionStatus[this.TEMPLATE_KEY.IncludeBeneficiary] =
          elapsedTimeAge != null && !this.beneficiaryIncludesGender
      }

      if (this.fundType === FundType.ChicagoLabor) {
        if (this.status) {
          this.submissionValues[this.TEMPLATE_KEY.EndDate] = null
          this.submissionStatus[this.TEMPLATE_KEY.EndDate] = false
        } else {
          this.submissionValues[this.TEMPLATE_KEY.EndDate] =
            this.submissionValues[this.TEMPLATE_KEY.FirstDateEligibleToRetire]
        }
      }
    }

    let mHtml = this.page.content.find(
      x => x.key === this.TEMPLATE_KEY.DisclaimerModal
    )
    if (mHtml) {
      this.modalHtml = mHtml.value
      this.disclaimerModalId = `Disclaimer_Accepted_${mHtml.id}`
      var disclaimerAccepted = sessionStorage.getItem(this.disclaimerModalId)
      if (!Boolean(disclaimerAccepted)) {
        setTimeout(() => {
          this.modalService.open(this.disclaimerModal, {
            backdrop: 'static',
            scrollable: true,
            keyboard: false,
            centered: true
          })
        }, 1500)
      }
    }

    let fHtml = this.page.content.find(
      x => x.key === this.TEMPLATE_KEY.ResultsFooter
    )
    if (fHtml) {
      this.footerHtml = fHtml.value
    }

    if (this.submissionValues['disableYOS'] != null) {
      this.disableYOS = this.submissionValues['disableYOS']
    }
  }

  buildDisplayStructure (): void {
    this.formInputs = []
    let content = this.page.content.filter(
      x =>
        !x.parentKey &&
        !(
          x.key === this.TEMPLATE_KEY.DisclaimerModal ||
          x.key === this.TEMPLATE_KEY.ResultsFooter
        )
    )

    for (let i = 0; i < content.length; i++) {
      let childCnt = this.page.content.filter(
        x => x.parentKey === content[i].key
      )
      let fInp: any = {
        key: content[i].key,
        name: content[i].value,
        value: childCnt.find(x => x.key.endsWith('Value'))?.value,
        helpText: childCnt.find(x => x.key.endsWith('HelpText'))?.value
      }
      if (childCnt.findIndex(x => x.key.endsWith('Granulation')) > 0) {
        fInp.granulation = childCnt.find(x =>
          x.key.endsWith('Granulation')
        ).value
      }
      if (
        fInp.key === this.TEMPLATE_KEY.Tier ||
        fInp.key === this.TEMPLATE_KEY.Gender
      ) {
        let metadataKey = fInp.value[0]
        this.store
          .select(MetadataSelectors.resolveProperty, metadataKey)
          .subscribe(mdProp => (fInp.listItems = mdProp.options))
      }
      if (fInp.key === this.TEMPLATE_KEY.IncludeBeneficiary) {
        fInp.includeGender = this.beneficiaryIncludesGender
      }

      this.formInputs.push(fInp)
      this.displayKeys[fInp.key.toLowerCase()] = fInp.name
    }
  }

  updateSubmissionValues (arg: any): void {
    let isRevised = localStorage.getItem('revised')
    if (!isRevised) {
      this.submissionValues[arg.key] = arg.value

      if (arg.key === this.TEMPLATE_KEY.Birthday) {
        this.startDateComponentRef.comesAfterDate =
          this.submissionValues[this.TEMPLATE_KEY.Birthday]
        this.startDateComponentRef.formFieldChanged(true)
      } else if (arg.key === this.TEMPLATE_KEY.StartDate) {
        this.birthdayComponentRef.comesBeforeDate =
          this.submissionValues[this.TEMPLATE_KEY.StartDate]
        this.birthdayComponentRef.formFieldChanged(true)
      }else if (arg.key === this.TEMPLATE_KEY.EndDate) {
        this.endDateComponentRef.comesAfterDate =
          this.submissionValues[this.TEMPLATE_KEY.StartDate]
        this.endDateComponentRef.formFieldChanged(true)
      }

      let hasYoSInput: Boolean =
        this.formInputs.findIndex(
          x => x.key === this.TEMPLATE_KEY.YearsOfService
        ) > 0
      if (
        (this.fundType === FundType.MobileAL ||
          this.fundType === FundType.WHG ||
          this.fundType === FundType.WHP ||
          this.fundType === FundType.WHF ||
          this.fundType === FundType.Largo) &&
        hasYoSInput &&
        this.hasBothDateValues &&
        (arg.key === this.TEMPLATE_KEY.StartDate ||
          arg.key === this.TEMPLATE_KEY.EndDate ||
          arg.key === this.TEMPLATE_KEY.LeaveTime ||
          arg.key === this.TEMPLATE_KEY.YearsOfService)
      ) {
        this.errorMessage = ''
        this.overwriteYOS = false
        this.submissionStatus[this.TEMPLATE_KEY.YearsOfService] = true
        if (arg.key !== this.TEMPLATE_KEY.YearsOfService) {
          let yearsOfService: any
          let memberAge: any
          let oldYearsOfService: any
          let oldMemberAge: any
          let tier: any

          if (this.fundType === FundType.MobileAL) {
            yearsOfService = this.calcUtilService.calculateYearsOfService(
              new Date(this.submissionValues[this.TEMPLATE_KEY.StartDate]),
              new Date(this.submissionValues[this.TEMPLATE_KEY.EndDate]),
              this.submissionValues[this.TEMPLATE_KEY.LeaveTime]
            )

            memberAge = this.calcUtilService.calculateMemberAge(
              new Date(this.submissionValues[this.TEMPLATE_KEY.Birthday]),
              new Date(this.submissionValues[this.TEMPLATE_KEY.EndDate])
            )
          } else {
            yearsOfService = this.calcUtilService.dateDiff(
              new Date(this.submissionValues[this.TEMPLATE_KEY.StartDate]),
              new Date(this.submissionValues[this.TEMPLATE_KEY.EndDate])
            )

            memberAge = this.calcUtilService.calculateMemberAge(
              new Date(this.submissionValues[this.TEMPLATE_KEY.Birthday]),
              new Date(this.submissionValues[this.TEMPLATE_KEY.EndDate])
            )

            if (this.fundType === FundType.WHG) {
              const specificDate = new Date(2017, 11, 20)
              oldYearsOfService = this.calcUtilService.dateDiff(
                new Date(this.submissionValues[this.TEMPLATE_KEY.StartDate]),
                specificDate
              )

              oldMemberAge = this.calcUtilService.calculateMemberAge(
                new Date(this.submissionValues[this.TEMPLATE_KEY.Birthday]),
                specificDate
              )
            }

            if (this.fundType === FundType.Largo) {
              tier = this.submissionValues[this.TEMPLATE_KEY.Tier]
            }
          }

          this.originalYOS = yearsOfService

          this.submissionValues[this.TEMPLATE_KEY.YearsOfService] =
            yearsOfService
          this.validateInput(
            memberAge.years,
            yearsOfService.years,
            oldMemberAge,
            oldYearsOfService,
            tier
          )
          this.disableYOS = this.errorMessage != ''
        } else {
          this.overwriteYOS =
            this.originalYOS == null ||
            (this.originalYOS != null &&
              JSON.stringify(
                this.submissionValues[this.TEMPLATE_KEY.YearsOfService]
              ) != JSON.stringify(this.originalYOS))
          if (this.overwriteYOS) {
            if (
              this.fundType === FundType.MobileAL &&
              this.submissionValues[this.TEMPLATE_KEY.YearsOfService].years < 15
            ) {
              this.errorMessage =
                'Years of Service is not eligible for annuity benefit.'
            } else if (
              this.submissionValues[this.TEMPLATE_KEY.YearsOfService].years < 10
            ) {
              this.errorMessage =
                'Projected Years of Service is not eligible for annuity benefit.'
            }
          }
        }
        this.isValid = this.errorMessage == ''
      } else if (
        !hasYoSInput &&
        this.hasBothDateValues &&
        (arg.key === this.TEMPLATE_KEY.StartDate ||
          arg.key === this.TEMPLATE_KEY.EndDate)
      ) {
        this.submissionValues[this.TEMPLATE_KEY.YearsOfService] =
          this.calcUtilService.calculateTimeElapsed(
            new Date(this.submissionValues[this.TEMPLATE_KEY.StartDate]),
            new Date(this.submissionValues[this.TEMPLATE_KEY.EndDate])
          )
      }
    } else {
      this.disableYOS = false
      if (this.formInputs[this.formInputs.length - 1].key === arg.key) {
        localStorage.removeItem('revised')
      }

      if (arg.key === this.TEMPLATE_KEY.YearsOfService) {
        this.overwriteYOS = this.submissionValues['overwriteYOS']
      }
    }
  const needCal = (this.fundType === FundType.MobileAL) && ((arg.key === this.TEMPLATE_KEY.StartDate) || this.TEMPLATE_KEY.EndDate);
  if (needCal && this.submissionValues[this.TEMPLATE_KEY.StartDate]
    && this.submissionValues[this.TEMPLATE_KEY.EndDate]
    && this.submissionStatus[this.TEMPLATE_KEY.StartDate]
    && this.submissionStatus[this.TEMPLATE_KEY.EndDate]) {
    if (!this.calProcessing && arg.key !== this.TEMPLATE_KEY.Salary) {
      this.calProcessing = true;
      let fasRequest: FASAutoCalculatorRequest = {
        calAsDate: new Date(this.submissionValues[this.TEMPLATE_KEY.EndDate]),
        dateOfHide: new Date(this.submissionValues[this.TEMPLATE_KEY.StartDate])
      }
      this.calculatorService.getFasAutoCal(fasRequest).pipe(
        catchError((err) => {
          this.calProcessing = false;
          return of(null);
        }),
        takeUntil(this.unsubscribe$)).subscribe((res: any) => {
          if (res) {
            this.updateSubmissionStatus({ key: this.TEMPLATE_KEY.Salary, isValid: true });
            this.submissionValues[this.TEMPLATE_KEY.Salary] = res.salary;
            this.calProcessing = false;
          }
        });
      }
    }
  }

  updateSubmissionStatus (arg: any): void {
    this.submissionStatus[arg.key] = arg.isValid
  }

  submitValues (): void {
    const element = this.elRef.nativeElement
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })

    if (!this.hasValidationErrors) {
      this.submissionValues['overwriteYOS'] = this.overwriteYOS
      this.submissionValues['status'] = this.status
      sessionStorage.setItem(
        this.submissionId,
        JSON.stringify(this.submissionValues)
      )
      this.store.dispatch(
        CalculatorActions.estimatePension({ values: this.submissionValues })
      )
      this.router.navigate(['pages', this.page.id, 'retirement'], {
        state: {
          calcName: this.page.header,
          footerHtml: this.footerHtml,
          displayKeys: this.displayKeys
        }
      })
    }
  }

  acceptDisclaimer (dismissReason: string) {
    this.loggingService.logEvent(`User Closed ${this.page.header} Disclaimer`, {
      accepted: true
    })
    this.modalService.dismissAll(dismissReason)

    sessionStorage.setItem(this.disclaimerModalId, 'true')
  }

  rejectDisclaimer (dismissReason: string) {
    this.loggingService.logEvent(`User Closed ${this.page.header} Disclaimer`, {
      accepted: false
    })
    this.modalService.dismissAll(dismissReason)

    this.location.back()
  }

  validateInput (
    memberAge: any,
    yearsOfService: any,
    oldMemberAge: any = null,
    oldYearsOfService: any = null,
    tier: any = null
  ) {
    if (yearsOfService > 99) {
      this.errorMessage = 'You cannot specify more than 99 Years.'
      return
    }

    switch (this.fundType) {
      case FundType.Largo:
        tier = tier != null ? tier.toUpperCase() : ''
        switch (tier) {
          case 'F':
            if (memberAge < 50 && yearsOfService >= 10 && yearsOfService < 23) {
              this.errorMessage =
                'Projected Retirement Age is not eligible for annuity benefit.'
            } else if (
              memberAge >= 50 &&
              memberAge < 62 &&
              yearsOfService < 10
            ) {
              this.errorMessage =
                'Projected Years of Service is not eligible for annuity benefit.'
            } else if (memberAge < 50 && yearsOfService < 10) {
              this.errorMessage =
                'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
            }

            break
          case 'P':
            if (memberAge < 50 && yearsOfService >= 10 && yearsOfService < 23) {
              this.errorMessage =
                'Projected Retirement Age is not eligible for annuity benefit.'
            } else if (
              memberAge >= 50 &&
              memberAge < 62 &&
              yearsOfService < 10
            ) {
              this.errorMessage =
                'Projected Years of Service is not eligible for annuity benefit.'
            } else if (memberAge < 50 && yearsOfService < 10) {
              this.errorMessage =
                'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
            }

            break
          case 'PN':
            if (memberAge < 50 && yearsOfService >= 10 && yearsOfService < 25) {
              this.errorMessage =
                'Projected Retirement Age is not eligible for annuity benefit.'
            } else if (
              memberAge >= 50 &&
              memberAge < 62 &&
              yearsOfService < 10
            ) {
              this.errorMessage =
                'Projected Years of Service is not eligible for annuity benefit.'
            } else if (memberAge < 50 && yearsOfService < 10) {
              this.errorMessage =
                'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
            }

            break
        }

        break
      case FundType.WHG:
        if (oldMemberAge.years >= 40 && oldYearsOfService.years >= 10) {
          if (memberAge < 50 && yearsOfService >= 10) {
            this.errorMessage =
              'Projected Retirement Age is not eligible for annuity benefit.'
          } else if (memberAge >= 50 && yearsOfService < 10) {
            this.errorMessage =
              'Projected Years of Service is not eligible for annuity benefit.'
          } else if (memberAge < 50 && yearsOfService < 10) {
            this.errorMessage =
              'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
          }
        } else if (yearsOfService < 33) {
          if (memberAge < 55 && yearsOfService >= 10) {
            this.errorMessage =
              'Projected Retirement Age is not eligible for annuity benefit.'
          } else if (memberAge >= 55 && yearsOfService < 10) {
            this.errorMessage =
              'Projected Years of Service is not eligible for annuity benefit.'
          } else if (memberAge < 55 && yearsOfService < 10) {
            this.errorMessage =
              'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
          }
        }
        break

      case FundType.WHP:
        if (memberAge < 50 && yearsOfService >= 10 && yearsOfService < 20) {
          this.errorMessage =
            'Projected Retirement Age is not eligible for annuity benefit.'
        } else if (memberAge >= 50 && memberAge < 60 && yearsOfService < 10) {
          this.errorMessage =
            'Projected Years of Service is not eligible for annuity benefit.'
        } else if (memberAge < 50 && yearsOfService < 10) {
          this.errorMessage =
            'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
        }
        break

      case FundType.WHF:
        if (memberAge < 45 && yearsOfService >= 10 && yearsOfService < 25) {
          this.errorMessage =
            'Projected Retirement Age is not eligible for annuity benefit.'
        } else if (memberAge >= 45 && yearsOfService < 10) {
          this.errorMessage =
            'Projected Years of Service is not eligible for annuity benefit.'
        } else if (memberAge < 45 && yearsOfService < 10) {
          this.errorMessage =
            'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
        }
        break

      case FundType.MobileAL:
        if(memberAge >= 65 && yearsOfService >= 15)
        {
          this.errorMessage = "";
        } else if ((memberAge < 55 && yearsOfService < 20) || (memberAge < 65 && yearsOfService < 15)) {
          this.errorMessage =
            'Combination of Projected Retirement Age and Years of Service is not eligible for annuity estimation.'
        } else if (memberAge < 55) {
          this.errorMessage =
            'Projected Retirement Age is not eligible for annuity benefit.'
        } else if (yearsOfService < 20) {
          this.errorMessage =
            'Years of Service is not eligible for annuity benefit.'
        }
        break
    }
  }
}
